import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { device } from "../device"
import { StaticImage } from "gatsby-plugin-image"

const MarchandisePage = () => {
  return (
    <Layout>
      <SEO title="marchandise" />
      <MarchandiseContainer>
        <MarchandiseItem>
          <ImgContainer>
            <StaticImage src="../images/sunday.jpg" alt="sunday" />
          </ImgContainer>
          <MarchandiseText>
            <Category>Book</Category>
            <ItemName>Miki Mochizuka Sun day</ItemName>
            <ul>
              <li>Book design: Junichi Tsunoda</li>
              <li>Size: 297 × 210 mm</li>
              <li>Pages: 82 pages, hardcover</li>
              <li>Illustrations: 52</li>
              <li>Language: Japanese/English</li>
              <li>Publisher: Vangi Sculpture Garden Museum, NOHARA</li>
              <li>Dec. 25, 2012</li>
              <li>ISBN 978-4-904257-15-9</li>
              <li>Price: 2,940 yen (tax included)</li>
              <li>December 25, 2012</li>
              <li>ISBN: 978-4-904257-15-9</li>
              <li>Price: 2,800yen (before-tax price)</li>
            </ul>
            <a href="http://www.nohara-publishing.com/all/post_13/index_e.php">
              order page
            </a>
          </MarchandiseText>
        </MarchandiseItem>
        <MarchandiseItem>
          <ImgContainer>
            <StaticImage src="../images/miki_badge_01.jpg" alt="miki badge01" />
          </ImgContainer>
          <MarchandiseText>
            <Category>Badge</Category>
            <ItemName>
              持塚三樹デザイン　クレマチスの丘ピンバッジ(うさぎ）
            </ItemName>
            <ul>
              <li>Size: 80 × 40 mm(台紙含む)</li>
              <li>Publisher: Vangi Sculpture Garden Museum</li>
              <li>Price: 380 yen (tax included)</li>
            </ul>
            <a href="http://www.noharabooks.jp/item.php?id=94">order page</a>
          </MarchandiseText>
        </MarchandiseItem>
        <MarchandiseItem>
          <ImgContainer>
            <StaticImage
              src="../images/miki_badge_02.jpg"
              alt="miki badge 02"
            />
          </ImgContainer>
          <MarchandiseText>
            <Category>Badge</Category>
            <ItemName>持塚三樹デザイン　クレマチスの丘ピンバッジ</ItemName>
            <ul>
              <li>Size: 80 × 40 mm(台紙含む)</li>
              <li>Publisher: Vangi Sculpture Garden Museum</li>
              <li>Price: 380 yen (tax included)</li>
            </ul>
            <p>out of stock</p>
          </MarchandiseText>
        </MarchandiseItem>
      </MarchandiseContainer>
    </Layout>
  )
}

const MarchandiseContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MarchandiseItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 0;
  border-bottom: 1px solid hsla(90, 80%, 40%, 0.15);

  &:first-child {
    padding-top: 0;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const ImgContainer = styled.div`
  display: flex;
  img {
    display: block;
    padding-right: 16px;
    max-width: 240px;
  }

  @media ${device.mobile} {
    img {
      padding: 0;
      width: 100%;
    }
  }
`

const Category = styled.span`
  display: block;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 0.8rem;
`

const MarchandiseText = styled.div`
  flex-shrink: 1;
  padding: 0 40px;
  font-family: ${props => props.theme.fontFamily.roboto};

  ul {
    font-size: 0.85rem;
    padding-left: 1rem;
    margin: 0;
  }

  a {
    display: block;
    margin: 16px 0;
    font-family: ${props => props.theme.fontFamily.roboto};
    color: hsla(90, 80%, 20%, 0.5);
  }

  @media ${device.mobile} {
    padding: 16px 0;
  }
`

const ItemName = styled.h3`
  font-family: ${props => props.theme.fontFamily.lora};
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 700;
`

export default MarchandisePage
